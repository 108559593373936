/* eslint-disable @typescript-eslint/no-empty-function */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import AnalyticsService from 'analytics-tracking-node';
import { AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import { Fragment, Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { TOKEN_NAME } from 'shared/constants';

import OfflineNotification from './components/OfflineNotification';
import { useInternetConnection } from './hooks';
import Loader from './loader';
import ProtectedRoute from './protectedRoutes';
import { handleUpdateUTM } from './redux/reducer/flam';
import { protectedRoutes, routes, RouteType } from './routes';
import GlobalStyles from './shared/globalStyles';


const serviceRequests = {
  enviornment: import.meta.env.VITE_ENVIRONMENT === 'prod' ? 'production' : 'sandbox',
  adjustToken: import.meta.env.VITE_ADJUST_TOKEN,
  tagManagerArgs: {
    gtmId: import.meta.env.VITE_GTM_ID,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
AnalyticsService.init(serviceRequests);

function AppRoutes() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();

  import.meta.env.VITE_ENVIRONMENT === 'prod' &&
    Sentry.init({
      dsn: 'https://0d3c998fbba64e4b96996d87fcd80bbe@o1276888.ingest.sentry.io/6474068',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      environment: import.meta.env.VITE_ENVIRONMENT,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });

  const disableConsole = () => {
    console.log('%c Welcome to Flam !', 'background: #212121; color: #f69510; padding: 6px;');
    console.log = () => {};
    console.error = () => {};
  };

  useEffect(() => {
    const query = new URLSearchParams(search);

    const utmMedium = query.get('utm_medium');
    const utmCampaign = query.get('utm_campaign');
    const utmSource = query.get('utm_source');
    const cjEvent = query.get('cjevent');

    const authTokenRex = query.get('au');

    if (location.pathname === '/order_history/' || location.pathname === '/order_history') {
      let url = 'https://us.flamapp.com/order-history';

      if (location.search) {
        url += location.search;
      }

      window.open(url, '_self');
    }

    // getEnviornment() === 'prod' && disableConsole();

    if (authTokenRex) {
      localStorage.setItem(TOKEN_NAME, authTokenRex);
      history.replace(location.pathname);
    }

    if (cjEvent) {
      Cookies.set('cje', cjEvent, {
        expires: 395,
        path: '.flamapp.com',
        secure: true,
      });
    }

    if (utmSource && utmMedium && utmCampaign) {
      dispatch(
        handleUpdateUTM({
          utmSource,
          utmMedium,
          utmCampaign,
        })
      );
      history.replace(location.pathname);
    }
  }, []);

  return (
    <Fragment>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Suspense fallback={<Loader />}>
          <Switch location={location} key={location.pathname}>
            {protectedRoutes.map((route: RouteType) => {
              return (
                <ProtectedRoute
                  path={route.path}
                  key={route.name}
                  component={() => <route.component />}
                />
              );
            })}
            {/* Quick fix for App */}
            <Route path="/add_videos">
              <Redirect to="/order" />
            </Route>
            {/* Quick fix for App */}
            <Route path="/order_history">
              <Redirect to="/order-history" />
            </Route>
            {/* Quick fix for App */}
            {routes.map((route: RouteType) => {
              return (
                <Route
                  exact={route.exact}
                  path={route.path}
                  key={route.name}
                  render={() => <route.component />}
                />
              );
            })}
          </Switch>
        </Suspense>
      </AnimatePresence>
    </Fragment>
  );
}

function App() {
  const isOffline = useInternetConnection();

  return (
    <BrowserRouter>
      <GlobalStyles isOffline={isOffline} />
      <Toaster position="top-right" />
      <HelmetProvider>
        <AppRoutes />
      </HelmetProvider>
      <OfflineNotification isOffline={isOffline} />
    </BrowserRouter>
  );
}

export default App;
