const lineClampStyle = (lineClamp: number) => `
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${lineClamp};
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
`;

const getWidthFromGridColumn = (gridColumn: number) =>
  `calc(${gridColumn} * 32px + ${gridColumn - 1} * 16px)`;

const hexToRgb = (hex: string, transparencyRatio: any) => {
  if (typeof hex !== 'string') return hex;
  const result: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return hex;

  const red = parseInt(result[1], 16);
  const green = parseInt(result[2], 16);
  const blue = parseInt(result[3], 16);
  return result ? `rgba(${red}, ${green}, ${blue}, ${transparencyRatio})` : hex;
};

const styleHelper = { lineClampStyle, getWidthFromGridColumn, hexToRgb };

export default styleHelper;
