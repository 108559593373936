import axios from 'axios';
import { TOKEN_NAME } from 'shared/constants';


/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable func-names */
export const http = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
});

export const httpSSO = axios.create({
  baseURL: import.meta.env.VITE_SSO_URL,
});

export const httpHulk = axios.create({
  baseURL: import.meta.env.VITE_HULK_URL,
});

// ----------------------------------------------------------------
//                     Axios Interceptor with token
// ----------------------------------------------------------------

http.interceptors.request.use(
  async (recdConfig: any) => {
    const config = recdConfig;

    const access_token = localStorage.getItem(TOKEN_NAME);

    try {
      if (access_token) {
        config.headers.common.Authorization = `Token ${access_token}`;
      }

      return config;
    } catch (err) {
      return config;
    }
  },

  (error: Error) => Promise.reject(error)
);

httpHulk.interceptors.request.use(
  async (recdConfig: any) => {
    const config = recdConfig;

    const access_token = localStorage.getItem(TOKEN_NAME);

    try {
      if (access_token) {
        config.headers.common.Authorization = `Token ${access_token}`;
      }

      return config;
    } catch (err) {
      return config;
    }
  },

  (error: Error) => Promise.reject(error)
);
