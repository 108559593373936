import axios from 'axios';

import { http } from './http';

const backendBaseURL = import.meta.env.VITE_BACKEND_URL;

// ----------------------------------------------------------------
//                     Cart
// ----------------------------------------------------------------

export const getCart = async () => {
  const res = await http.get('/orders/v3/cart');
  return res;
};

export const clearCart = async () => {
  const res = await http.get('/orders/v3/emptycart');
  return res;
};

// ----------------------------------------------------------------
//                     Updation of Cart Items
// ----------------------------------------------------------------

export const changeQuantity = async (data: any) => {
  const res = await http.post('/orders/v3/quantity', data);
  return res;
};

// ----------------------------------------------------------------
//                     Posts
// ----------------------------------------------------------------

export const getSignedURL = async (data: any) => {
  const res = await http.post('/orders/v3/signed_url', data);
  return res.data;
};

export const UploadImageURL = async (signedURL: any, file: any) => {
  const res = await axios({
    method: 'put',
    url: signedURL,
    data: file,
    headers: {
      'Content-Type': file.type,
    },
  });
  return res.data;
};

export const addPhotos = async (data: any) => {
  const res = await http.post('/orders/v3/post', data);
  return res;
};

export const getOrderHistory = async (type?: 'INDIAD2C' | 'USD2C' | 'PHYSICAL_US' | 'INSTANT') => {
  let URL = `/orders/v3/orderhistoryweb`;

  if (type) {
    URL += `?source=${type}`;
  }

  const res = await http.get(URL);
  return res.data;
};

// ----------------------------------------------------------------
//                     Coupons
// ----------------------------------------------------------------

export const getPromocodes = async () => {
  const res = await http.get('/orders/promocode');
  return res.data;
};

export const validatePromocode = async (data: { name: string }) => {
  const res = await http.post('/orders/promocode', data);
  return res.data;
};

// ----------------------------------------------------------------
//                     Self Pickup
// ----------------------------------------------------------------

export const getWallgreensByZip = async (zip: number) => {
  const res = await http.get(`/operations/zip?zip=${zip}`);
  return res.data;
};

// ----------------------------------------------------------------
//                     Feedback
// ----------------------------------------------------------------

export const getFeedbackQuestions = async () => {
  const res = await http.get(`/orders/v3/feedback`);
  return res.data;
};

export const postSocialAnswer = async (data: any) => {
  const res = await http.post(`/orders/v3/feedback`, data);
  return res.data;
};

export const postRatingAnswer = async (data: any) => {
  const res = await http.post(`/orders/v3/feedback`, data);
  return res.data;
};

// ----------------------------------------------------------------
//                     Bounty Order
// ----------------------------------------------------------------

export const getBountyOrderData = async (id: string) => {
  const res = await axios.get(`${backendBaseURL}/orders/v3/orderdetails?ano_id=${id}`);
  return res.data;
};
