import { ReactComponent as Add } from 'assets/vectors/Add.svg';
import { ReactComponent as Airplane } from 'assets/vectors/airplane.svg';
import { ReactComponent as Arrow } from 'assets/vectors/arrow.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowdown.svg';
import { ReactComponent as Bag } from 'assets/vectors/Bag.svg';
import { ReactComponent as Cart } from 'assets/vectors/CartIcon.svg';
import { ReactComponent as Close } from 'assets/vectors/Close.svg';
import { ReactComponent as CoinBag } from 'assets/vectors/coinBag.svg';
import { ReactComponent as copy } from 'assets/vectors/copy.svg';
import { ReactComponent as Country } from 'assets/vectors/country.svg';
import { ReactComponent as Coupon } from 'assets/vectors/coupon.svg';
import { ReactComponent as Decrement } from 'assets/vectors/Decrement.svg';
import { ReactComponent as EmptyStar } from 'assets/vectors/empty_star.svg';
import { ReactComponent as ErrorImage } from 'assets/vectors/EmptyCart.svg';
import { ReactComponent as Facebook } from 'assets/vectors/facebook.svg';
import { ReactComponent as FilledStar } from 'assets/vectors/filled_star.svg';
import { ReactComponent as Question } from 'assets/vectors/Help.svg';
import { ReactComponent as Image } from 'assets/vectors/ImageFill.svg';
import { ReactComponent as Increament } from 'assets/vectors/Increament.svg';
import { ReactComponent as Instagram } from 'assets/vectors/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/vectors/linkedin.svg';
import { ReactComponent as Login } from 'assets/vectors/Login.svg';
import { ReactComponent as Logo } from 'assets/vectors/Logo.svg';
import { ReactComponent as Map } from 'assets/vectors/Map.svg';
import { ReactComponent as NoFlamCards } from 'assets/vectors/NoFlamCards.svg';
import { ReactComponent as Notebook } from 'assets/vectors/Notebook.svg';
import { ReactComponent as Share } from 'assets/vectors/Share.svg';
import { ReactComponent as Signout } from 'assets/vectors/SignOut.svg';
import { ReactComponent as Time } from 'assets/vectors/TimeFill.svg';
import { ReactComponent as Twitter } from 'assets/vectors/twitter.svg';
import { ReactComponent as Underline } from 'assets/vectors/Underline.svg';
import { ReactComponent as Warning } from 'assets/vectors/Warning.svg';
import { ReactComponent as Youtube } from 'assets/vectors/youtube.svg';

const icons: any = {
  errorImage: ErrorImage,
  add: Add,
  bag: Bag,
  logo: Logo,
  close: Close,
  arrow: Arrow,
  arrowDown: ArrowDown,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
  youtube: Youtube,
  underline: Underline,
  airplane: Airplane,
  cart: Cart,
  image: Image,
  time: Time,
  increment: Increament,
  decrement: Decrement,
  question: Question,
  signIn: Login,
  notebook: Notebook,
  signOut: Signout,
  noFlamCards: NoFlamCards,
  warning: Warning,
  map: Map,
  coupon: Coupon,
  country: Country,
  share: Share,
  emptyStar: EmptyStar,
  filledStar: FilledStar,
  coinBag: CoinBag,
  copy: copy,
};

const Icon = ({ icon, pointer = false, ...props }: any) => {
  const IconComponent = icons[icon];
  return <IconComponent style={{ cursor: pointer ? 'pointer' : 'auto' }} aria-hidden {...props} />;
};

export default Icon;
