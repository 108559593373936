import styled from '@emotion/styled';
import { H6, Icon } from 'components/Base';
import { SPACING_2B, SPACING_4B } from 'shared/styleConstants/constants/spacing';

const Overlay = styled.div<{ isVisible: any }>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999999999;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const InternetInfo = styled.div<{ isVisible: any }>`
  margin: auto;
  position: fixed;
  top: ${({ isVisible }) => (isVisible ? '50px' : '30px')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  left: 0;
  right: 0;
  background-color: var(--color-gray-1);
  display: inline-block;
  width: 250px;
  border-radius: ${SPACING_2B};
  text-align: center;
  padding: ${SPACING_4B};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: top 300ms ease-in, opacity 300ms ease-in, visibility 300ms ease-in;
  z-index: 9999999999;
`;

const InternetInfoText = styled(H6)`
  color: var(--color-white);

  svg {
    vertical-align: middle;
    margin-right: ${SPACING_2B};
    width: 20px;
    height: 20px;
  }
`;

export default function OfflineNotification({ isOffline }: any) {
  return (
    <>
      <Overlay isVisible={isOffline} />
      <InternetInfo isVisible={isOffline}>
        <InternetInfoText>
          <Icon icon="warning" />
          No internet connection
        </InternetInfoText>
      </InternetInfo>
    </>
  );
}
