import styled from '@emotion/styled';
import { Fonts, Spacings } from 'shared/styleConstants';
import styleHelper from 'utils/styleHelper';

import { TypographyProps } from './typographyProps';

const P2 = styled.p<TypographyProps>`
  font-weight: ${Fonts.REGULAR};
  font-size: ${Fonts.P2};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  margin: ${Spacings.SPACING_0B};
  color: ${({ color }) => (color ? `var(--color-${color})` : 'var(--color-white)')};
  -webkit-line-clamp: ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null};
`;

export default P2;
