export { TextConstants } from './textConstants';
export { NAV_ACTIONS, NAV_LINKS } from './navConstants';

export const TOKEN_NAME = 'flamToken';

export const MEDIA_COMPRESSOR_URL =
  'https://media.homingos.com/imgmanip?width=400&height=400&mediaUrl=';

export const PRINTED_FLAM_CARD_CONSTANTS = {
  HEADING: "What's in it for you",
  DESCRIPTION:
    'Upload photos and see your FlamCards turn into an AR experience by adding videos and 3D effects after placing your order.',
  INFO: {
    MATERIAL: '7” x 5” 300 GSM matte finish paper',
    DELIVERY: '7-10 Days delivery',
  },
  UPLOAD_BUTTON_LABEL: 'Upload',
};

export const LOGIN_SCREEN_CONSTANTS = {
  phone_number: {
    heading: 'Login',
    sub_heading: 'Enter your mobile number to proceed. We’ll use it to keep your FlamCards safe.',
    placeholder_phone: 'Phone Number',
    button: 'Continue',
  },
  otp: {
    heading: 'Enter OTP',
    sub_heading: 'Enter the code we sent over SMS to ',
    placeholder_otp: 'OTP',
    button: 'Continue',
  },
  referral: {
    heading: 'Enter referral code',
    sub_heading: 'Enter a referral code to create instant FlamCard',
    placeholder_referral: 'Referral Code',
    button: 'Submit',
  },
  signup: {
    heading: 'Enter your details',
    sub_heading: 'We need your name and email address to continue',
    placeholder_name: 'Name',
    placeholder_email: 'Email (optional)',
    button: 'Submit',
  },
};

export const AUTH_HELPERS_CONSTANTS = {
  HELP_TEXT: 'Need help? Watch tutorial video',
  POLICY: 'You agree to terms of use and privacy policy.',
};

export const ADD_FLAM_CARDS = {
  HEADING: 'Add FlamCards',
};

export const UPLOADED_FLAM_CARDS = {
  CHANGE_EFFECT_BTN_LABEL: 'change effect',
  EFFECT: '3D Hearts',
};

export const AUTH_ROUTES = ['/order/', '/cart/'];
export const UNAUTH_ROUTES = ['/login/'];

export const NAVBAR_CONSTANTS = {
  GREETING: 'Welcome',
  NOT_LOGGED_IN: 'You are not logged in',
};

export const HEROPAGE_CONSTANTS = {
  MAINTEXT: 'FlamCards',
  GREETING: "A photo on your desk when it's still, moving memory when it's scanned.",
  SUBDESCRIPTION:
    'Turn your photos into AR videos. Use 3D effects and watch them come alive every time you scan using the Flam app.',
  SHIPPING: 'Free shipping across country',
};

export const DETAILSPAGE_CONSTANTS = {
  HEADING: 'It’s super easy and quick. Download the Flam app and follow these steps.',
  SUBHEADING: 'Let the fun begin!',
  CARD_ONE_HEADING: 'Create your FlamCards',
  CARD_ONE_DESCRIPTION: 'Upload a bunch of your photos and get started.',
  CARD_TWO_HEADING: 'Free Shipping',
  CARD_TWO_DESCRIPTION:
    'Provide us your address. And we promise to send your FlamCards at no cost.',
  CARD_THREE_HEADING: 'AR experience',
  CARD_THREE_DESCRIPTION:
    'Using Flamapp, Scan & add new videos to your FlamCard anytime, any day, anywhere!',
};
