import { http } from './http';

// ----------------------------------------------------------------
//                     SKU
// ----------------------------------------------------------------

export const getSKU = async () => {
  const res = await http.get('/orders/v3/sku');
  return res;
};

export const updateSKU = async (data: any) => {
  const res = await http.post('/orders/v3/changesku', data);
  return res;
};
