import styled from '@emotion/styled';
import { Fonts, Spacings } from 'shared/styleConstants';
import styleHelper from 'utils/styleHelper';

import { TypographyProps } from './typographyProps';

const P1 = styled.p<TypographyProps>`
  font-weight: ${Fonts.REGULAR};
  font-size: ${Fonts.P1};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  color: ${({ color }) => (color ? `var(--color-${color})` : 'var(--color-white)')};
  margin: ${Spacings.SPACING_0B};
  -webkit-line-clamp: ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null};
  text-align: ${({ center }) => center && 'center'};
`;

export default P1;
