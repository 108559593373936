const defaultColors = `
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-black-1: #111111;
  --color-black-2: #323232;
  --color-black-3: linear-gradient(167.96deg, #313545 0%, #121118 100%);
  --color-black-4: #333333;
  --color-black-5: #C4C4C4;
  --color-gray-1: #808080;
  --color-gray-2: #656565;
  --color-gray-3: #8D8D8D;
  --color-gray-4: #E0E0E0;
`;

const appColors = `
  --color-background: #111111;
  --color-primary: #1B1B1B;
  --color-secondary: #8443D8;
  --color-info: #FFFFFF;
  --color-success: #3EC67F;
  --color-warning: #FFB114;
  --color-error: #E4632B;
`;

const gradients = `
  --color-ascent: linear-gradient(98.66deg, #D29F1F -6.39%, rgba(250, 226, 166, 0.6) 150.78%), radial-gradient(202.39% 288.79% at -24.9% -16.38%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(74.17deg, #576265 13.43%, #757A7B 40.71%, #576265 53.73%, #848B8A 63.95%, #9EA1A1 72.78%, #576265 77.78%, #C4C4C4 88.3%);
`;

const theme = `
  ${defaultColors}
  ${appColors}
  ${gradients}
`;

export default theme;
