import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import styled from '@emotion/styled';

const ContainerBox = styled.div`
  border: 1px;
  width: 376px;
  height: 50px;
  border-radius: 6px;
`;

export default function RadioButtonsGroup() {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="me"
        name="radio-buttons-group"
      >
        <ContainerBox>
          <FormControlLabel value="me" control={<Radio />} label="I will pickup" />
        </ContainerBox>
        <FormControlLabel
          value="someone-else"
          control={<Radio />}
          label="Someone else will pickup "
        />
      </RadioGroup>
    </FormControl>
  );
}
