import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable operator-linebreak */
/* eslint-disable arrow-parens */
const initialState = {
  orderID: '',
  skuId: '',
  addressID: '',
  displayPrice: 0,
  skuPriceFactor: 1,
  billDetails: {
    promoCode: '',
    discountAmount: 0,
    promoCodeDesc: '',
    promoCodeId: '',
    isLottery: false,
  },
  finalAmount: 0,
  cartData: [],
  cartPrice: 0,
  maxQueue: 1,
  cartCount: 0,
  paymentProcessed: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: { value: initialState },
  reducers: {
    handleCartUpdate: (state: any, action: any) => {
      state.value.orderID = action.payload.id;
      state.value.skuId = action.payload.skuId;
      state.value.cartData = action.payload.videocards;
      state.value.cartPrice = action.payload.price;
      state.value.cartCount = action.payload.videocardCount;
    },
    handleSkuUpdate: (state: any, action: any) => {
      state.value.skuId = action.payload.skuId;
      state.value.skuPriceFactor = action.payload.skuPriceFactor;
      state.value.displayPrice = action.payload.displayPrice;
      state.value.maxQueue = action.payload.maxQueue;

      if (state.value.billDetails.promoCodeId === '') {
        state.value.finalAmount = action.payload.displayPrice;
      }
    },
    handleCartAddressUpdate: (state: any, action: any) => {
      state.value.addressID = action.payload.addressID;
    },
    handleEmptyCart: (state: any) => {
      state.value = initialState;
    },
    handlePayemntProcessed: (state: any) => {
      state.value.paymentProcessed = !state.value.paymentProcessed;
    },
    handlePromoCodeApplied: (state: any, action: any) => {
      const discount =
        action.payload.discount_type === 'PERCENTAGE'
          ? ((state.value.displayPrice * action.payload.discount) / 100).toFixed(2)
          : action.payload.discount;

      // console.log(action.payload);
      state.value.billDetails.promoCode = action.payload.name;
      state.value.billDetails.discountAmount = discount;
      state.value.finalAmount = (state.value.displayPrice - discount).toFixed(2);
      state.value.billDetails.promoCodeDesc = action.payload.description;
      state.value.billDetails.promoCodeId = action.payload.id;
      state.value.billDetails.isLottery = action.payload.is_lottery;
    },
    handleRemovePromoCode: (state: any) => {
      state.value.billDetails = initialState.billDetails;
      state.value.finalAmount = state.value.displayPrice;
    },
  },
});

export const {
  handleSkuUpdate,
  handleCartUpdate,
  handleCartAddressUpdate,
  handleEmptyCart,
  handlePayemntProcessed,
  handlePromoCodeApplied,
  handleRemovePromoCode,
} = cartSlice.actions;

export default cartSlice.reducer;
