import styled from '@emotion/styled';
import { Button, H6, Icon, P2 } from 'components/Base';
import { motion } from 'framer-motion';
import { useOnClickOutside } from 'hooks';
import React, { useRef } from 'react';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 4;

  .modal-background {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    animation: opacity 200ms ease-in-out;
  }

  .modal-inner {
    z-index: 5;
    position: relative;
    width: 330px;
    height: 420px;
    background: #1b1b1b;
    border: 1.53746px solid #333333;
    box-sizing: border-box;
    border-radius: 12px;
    animation: opacity 200ms ease-in-out;
    display: grid;
    grid-template-rows: 50% 50%;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const CloseIconWrapper = styled(motion.div)`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  height: 25px;
  width: 25px;
`;

const ImageContainer = styled.div`
  background: linear-gradient(167.96deg, #313545 0%, #121118 100%);
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;

  svg {
    height: 120px;
  }
`;

const TextContainer = styled.div`
  padding: 2rem;
  align-items: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`;

interface Props {
  title: string;
  modalIcon?: any;
  primaryButtonFunction?: () => void;
  primaryButton?: string;
  setToggleModal: (val: boolean) => void;
  useNoRef?: boolean | false;
  desc?: string;
}

const Modal: React.FC<Props> = (props) => {
  const { title = 'Common Modal' } = props;

  const modalRef = useRef(null);
  !props.useNoRef && useOnClickOutside(modalRef, () => props.setToggleModal(false));

  return (
    <ModalContainer>
      <div className="modal-background">
        <div className="modal-inner" ref={modalRef}>
          <CloseIconWrapper onClick={() => props.setToggleModal(false)}>
            <Icon pointer icon="close" />
          </CloseIconWrapper>
          {props.modalIcon && <ImageContainer>{props.modalIcon}</ImageContainer>}
          <TextContainer>
            {props.title && <H6>{props.title}</H6>}
            {props.desc && <P2>{props.desc}</P2>}
            {props.primaryButton && (
              <Button
                type="button"
                text={props.primaryButton}
                size="lg"
                performTask={props.primaryButtonFunction}
                variant={'secondary'}
              />
            )}
          </TextContainer>
        </div>
      </div>
    </ModalContainer>
  );
};

export default Modal;
