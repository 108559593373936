import styled from '@emotion/styled';
import React, { useState } from 'react';

interface Props {
  options: any;
  state: string;
  setState: (state: string) => void;
}

const DropdownInput = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownPlaceholder = styled.div`
  position: relative;
  outline: none;
  border: 0;
  background: #1b1b1b;
  border: 1.33368px solid #333333;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.8rem;
  color: #ffffff;
  max-width: 396px;
  width: 100%;
  transition: all 0.1s ease-in;
  font-weight: medium;
  margin: 1rem 0rem;
  z-index: 0;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    right: 1rem;
    top: 50%;
    transform: translateY(-25%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #fff;
  }

  :focus {
    border: 1px solid lightgray;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  width: 100%;
  max-height: 15rem;
  overflow: auto;
  z-index: 99;
  padding: 0.2rem 0.2rem;
  border-radius: 5px;
  background-color: var(--color-white);
  box-shadow: 4px 4px 15px -5px var(--color-black);
  top: 85%;
`;

const DropdownItem = styled.div`
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  color: var(--color-black);
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  z-index: 4;

  &:hover {
    color: var(--color-white);
    background: var(--color-error);
  }
`;

const DropDownMenu: React.FC<Props> = (props: any) => {
  const [dropdown, setDropdown] = useState(false);
  const categoryRef = React.useRef<HTMLDivElement>(null);
  //   useOnClickOutside(categoryRef, () => setDropdown(false));

  return (
    <DropdownInput ref={categoryRef} onClick={() => setDropdown(!dropdown)}>
      <DropdownPlaceholder>
        {props.state ? props.state : props.options[0].value}
      </DropdownPlaceholder>
      {dropdown && props.disabled !== true && (
        <DropdownContainer>
          {props.options.map((option: any, index: number) => (
            <DropdownItem key={index} onClick={() => props.setState(option.value)}>
              {option.option}
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </DropdownInput>
  );
};

export default DropDownMenu;
