// React Imports
import { lazy } from 'react';

const Home = lazy(() => import('./pages/Home'));

// Main Flow
const Cart = lazy(() => import('./pages/Cart'));
const Owner = lazy(() => import('./pages/Owner'));

// Order FLow
const Order = lazy(() => import('./pages/Order/pages/'));
const OrderHistory = lazy(() => import('./pages/Order/pages/history'));
const ThankYou = lazy(() => import('./pages/Order/pages/thankyou'));

// Extra Pages
const HelpPage = lazy(() => import('./pages/Help'));
const Faq = lazy(() => import('./pages/Faq'));

// Auth Pages
const Login = lazy(() => import('./pages/Auth/Login'));

// Error Pages
const Errror404 = lazy(() => import('./pages/Errors/404'));

// Misc Pages
const WIP = lazy(() => import('./pages/Misc/WIP'));

const TnC = lazy(() => import('./pages/Docs/TermsAndConditions'));

// Bounty Pages
const Bounty = lazy(() => import('./pages/Bounty'));

export interface RouteType {
  path: string;
  name: string;
  component: any;
  exact?: boolean;
}

export const protectedRoutes: RouteType[] = [
  {
    path: '/order', // path
    name: 'Order', // label
    component: Order, // component
    exact: true,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    exact: true,
  },
  {
    path: '/thankyou',
    name: 'Thank You',
    component: ThankYou,
    exact: true,
  },
  {
    path: '/order-history',
    name: 'Order History',
    component: OrderHistory,
    exact: true,
  },
  {
    path: '/owner/:anv_id', // path
    name: 'Owner', // label
    component: Owner, // component
    exact: true,
  },
];

export const routes: RouteType[] = [
  {
    path: '/', // path
    name: 'landing', // label
    component: Home, //component
    exact: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    exact: true,
  },
  {
    path: '/help',
    name: 'help',
    component: HelpPage,
    exact: true,
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: WIP,
    exact: true,
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    exact: true,
  },
  {
    path: '/tnc',
    name: 'terms',
    component: TnC,
    exact: true,
  },
  { path: '/bounty/:bountyId',
    name: 'bounty',
    component: Bounty,
    exact: true 
  },
  {
    path: '*',
    name: 'notFound',
    component: Errror404,
  },
];
