import { combineReducers } from '@reduxjs/toolkit';

import cart from './reducer/cart';
import flam from './reducer/flam';
import sku from './reducer/sku';
import user from './reducer/user';

const rootReducer = combineReducers({
  user: user,
  cart: cart,
  sku: sku,
  flam: flam,
});

export default rootReducer;
