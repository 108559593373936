/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/comma-dangle */
import styled from '@emotion/styled';
import React, { Fragment } from 'react';

const StyledInput = styled.input<any>`
  display: flex;
  outline: none;
  border: 0;
  background: #1b1b1b;
  border: 1.33368px solid #333333;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.8rem;
  color: #ffffff;
  max-width: 396px;
  width: 100%;
  transition: all 0.2s ease-in;
  font-weight: medium;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  ::placeholder {
    color: #8d8d8d;
  }

  :focus {
    border: 1px solid lightgray;
  }

  :disabled {
    color: var(--color-gray-1);
  }

  /* Chrome, Safari, Edge, Opera */

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  [type='number'] {
    -moz-appearance: textfield;
  }

  ${({ error }) =>
    error &&
    `
    border-color: var(--color-error) !important;
    border-width: 1px;
    background-size: 0;
    margin-bottom: 0.5rem;
  `};
`;

const Text = styled.p`
  margin: 8px auto;
`;

const Error = styled.p<any>`
  font-size: 12px;
  color: var(--color-error);
  margin-bottom: ${({ error }) => (error ? '0.5rem' : '1rem')};
  transition: all 0.2s ease-in;
`;

const StyledInputContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  text-align: left;
  width: 100%;
`;

export type Ref = any;

type Props = {
  id?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  // hasError,
  // isTouched,
  hintText?: string;
  type?: string;
  ChangeHandler?: any;
  disabled?: boolean;
  className?: string;
  error?: string;
  maxLength?: string | number;
};

const TextField = React.forwardRef<Ref, Props>((props, ref) => (
  <StyledInputContainer>
    <StyledInput
      id={props.id}
      name={props.name}
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}
      ref={ref}
      className={props.className}
      onChange={props.ChangeHandler}
      disabled={props.disabled}
      error={props.error}
      maxLength={props.maxLength}
      {...props}
    />
    {props.hintText && <Text>{props.hintText}</Text>}
    {props.error && <Error error={props.error}>{props.error}</Error>}
  </StyledInputContainer>
));

export default TextField;
