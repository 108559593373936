import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';
import { mediaQuery } from 'shared/styleConstants';

import Icon from './Icon';

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  zindex: 100;
  cursor: pointer;

  ${mediaQuery.uptoMobile} {
    top: 1.5rem;
    right: 1.5rem;
  }
`;

const variants = {
  closed: { display: 'hidden' },
  open: { display: 'block' },
};

export default function Drawer(props: any) {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <Box
      sx={{
        backgroundColor: '#1B1B1B',
        color: 'white',
      }}
      width={{ sm: '100vw', md: 500 }}
      height={'100vh'}
      overflow={'auto'}
    >
      {' '}
      <CloseIconWrapper onClick={toggleDrawer(false)}>
        <Icon icon="close" sx={{ zIndex: 100 }} />
      </CloseIconWrapper>
      {props.ContentBox}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={'left'}>
        <div onClick={toggleDrawer(true)} onKeyDown={toggleDrawer(true)} role="button" tabIndex={0}>
          {props.children && props.children}
        </div>
        <SwipeableDrawer
          anchor="left"
          open={state}
          onClose={toggleDrawer(true)}
          onOpen={toggleDrawer(true)}
          transitionDuration={500}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
