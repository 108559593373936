import styled from '@emotion/styled';
import { mediaQuery } from 'shared/styleConstants';

const BlockContainerCard = styled.div`
  display: grid;
  place-items: center;
  background: #1b1b1b;
  border: 1.4px solid rgba(51, 51, 51, 0.4);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 4rem 8rem;
  max-width: 605px;
  justify-items: left;
  transition: all 150ms ease-in-out;
  width: 90%;
  margin-bottom: 1rem;

  ${mediaQuery.uptoTablet} {
    padding: 1rem;
  }

  ${mediaQuery.uptoMobile} {
    padding: 2rem 1rem;
  }
`;

export default BlockContainerCard;
