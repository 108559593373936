import styled from '@emotion/styled';
import React from 'react';
import { Fonts } from 'shared/styleConstants';
import ButtonLoader from './components/ButtonLoader';

/* eslint-disable implicit-arrow-linebreak */
interface ButtonProps {
  text: string;
  variant: 'primary' | 'secondary' | 'outlined';
  size?: 'lg' | 'md' | 'sm';

  // Optional
  color?: string;
  icon?: React.ReactNode;
  performTask?: () => void;
  border?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon_before?: any;
  icon_after?: any;
  style?: any;
  type?: 'button' | 'reset' | 'submit' | undefined;
}

const DefaultProps: ButtonProps = {
  color: 'gradient',
  variant: 'primary',
  text: 'Empty Button',
  size: 'sm',
  type: 'button',
  icon_before: false,
  disabled: false,
};

const theme = {
  primary: {
    background: 'error',
    color: 'white',
  },
  secondary: {
    background: 'secondary',
    color: 'white',
  },
  outlined: {
    background: '',
    color: 'secondary',
  },
};

const ButtonContainer = styled.button<ButtonProps>`
  outline: none;
  border: none;
  border-radius: 8px;
  width: 100%;
  max-width: 396px;
  height: 50px;
  cursor: pointer;
  background: ${(props) =>
    props.variant ? `var(--color-${theme[props.variant].background})` : 'transparent'};

  color: ${(props) =>
    props.variant ? `var(--color-${theme[props.variant].color})` : 'var(--color-secondary)'};

  border: ${(props) =>
    props.variant === 'outlined' && `2px solid var(--color-${theme[props.variant].color})`};

  font-weight: ${Fonts.HEAVY};
  font-size: ${Fonts.H6};
  line-height: 22px;

  &:disabled {
    background: var(--color-black-4);
    border: none;
    color: var(--color-white);
    cursor: not-allowed;
  }
`;

const Button: React.FC<ButtonProps> = (props: ButtonProps) => (
  <ButtonContainer
    type={props.type}
    variant={props.variant}
    onClick={props.performTask}
    disabled={props.disabled || props.loading}
    text={''}
  >
    {props.loading ? <ButtonLoader /> : props.text}
  </ButtonContainer>
);

Button.defaultProps = DefaultProps;

export default React.memo(Button);
