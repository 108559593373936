/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable arrow-parens */
const initialState = {
  skuData: [],
};

const skuSlice = createSlice({
  name: 'sku',
  initialState: { value: initialState },
  reducers: {
    handleAllSku: (state, action) => {
      state.value.skuData = action.payload;
    },
  },
});

export const { handleAllSku } = skuSlice.actions;

export default skuSlice.reducer;
