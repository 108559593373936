import styled from '@emotion/styled';
import { Fonts } from 'shared/styleConstants';
import styleHelper from 'utils/styleHelper';

import { TypographyProps } from './typographyProps';

const H3 = styled.h3<TypographyProps>`
  font-weight: ${Fonts.HEAVY};
  font-size: ${Fonts.H3};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  color: ${({ color }) => (color ? `var(--color-${color})` : 'var(--color-white)')};
  margin: 0;
  -webkit-line-clamp: ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null};
`;

export default H3;
