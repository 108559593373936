import { http, httpHulk } from './http';

// ----------------------------------------------------------------
//                     Profile
// ----------------------------------------------------------------

export const getProfile = async () => {
  const res = await http.get('/accounts/profile');
  return res;
};

// ----------------------------------------------------------------
//                     User Address
// ----------------------------------------------------------------

export const postUserAddress = async (data: any) => {
  const res = await http.post('/operations/address', data);
  return res;
};

// export const deleteUserAddress = async (id: any) => {
//   const res = await http.delete(`/operations/editAddress/${id}`);
//   return res;
// };

// ----------------------------------------------------------------
//                     Network (Flam Card Access)
// ----------------------------------------------------------------

export const getCardAccessRequests = async (anvId: string) => {
  const res = await httpHulk.get(`/network/get_requests?anv_id=${anvId}`, {
    headers: {
      Authorization: `Token 61727065-3518-49f2-aecf-b2a44ad197d4`,
    },
  });

  return res.data;
};

export const getCardAccessMembers = async (anvId: string) => {
  const res = await httpHulk.get(`/network/get_members?anv_id=${anvId}`, {
    headers: {
      Authorization: `Token 61727065-3518-49f2-aecf-b2a44ad197d4`,
    },
  });

  return res.data;
};

export const inviteMemberToCard: any = async (
  anvId: string,
  name: string,
  phoneNumber: string | number
) => {
  console.log('INVITE', anvId);
  const res = await httpHulk.post(
    `/network/invite_member`,
    {
      anv_id: anvId,
      name: name,
      phone_number: phoneNumber,
      anoId: anvId,
    },
    {
      headers: {
        Authorization: `Token 61727065-3518-49f2-aecf-b2a44ad197d4`,
      },
    }
  );

  return res.data;
};

export const revokeMemberFromCard: any = async (
  anvId: string,
  name: string,
  phoneNumber: string | number
) => {
  const res = await httpHulk.post(
    `/network/revoke_access`,
    {
      anv_id: anvId,
      phone_number: phoneNumber,
      name: name,
      anoId: anvId,
    },
    {
      headers: {
        Authorization: `Token 61727065-3518-49f2-aecf-b2a44ad197d4`,
      },
    }
  );

  return res.data;
};

export const getRefferalData: any = async () => {
  const res = await http.get(`/accounts/referral`);
  return res.data;
};
