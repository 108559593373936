/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable arrow-parens */
const initialState = {
  utmSource: 'flamapp',
  utmMedium: 'website',
  utmCampaign: 'organic',
  pageVariant: 'A',
  locale: {
    countryName: 'India',
    countryCode: 'IN',
    currencyCode: 'INR',
    currencySymbol: '₹',
    phoneMinLength: '10',
    phoneMaxLength: '10',
  },
};

const skuSlice = createSlice({
  name: 'flam',
  initialState: { value: initialState },
  reducers: {
    handleUpdateUTM: (state: any, action: any) => {
      state.value.utmSource = action.payload.utmSource;
      state.value.utmMedium = action.payload.utmMedium;
      state.value.utmCampaign = action.payload.utmCampaign;
    },
    handleUpdateLocale: (state: any, action: any) => {
      state.value.locale = action.payload;
    },
  },
});

export const { handleUpdateUTM, handleUpdateLocale } = skuSlice.actions;

export default skuSlice.reducer;
