import { css, Global } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import { Theme } from 'shared/styleConstants';


interface GlobalStylesProps {
  isOffline?: boolean;
  isMobile?: boolean;
}

const GlobalStyles = (Props: GlobalStylesProps) => (
  <Global
    styles={css`
      ${emotionNormalize}

      html {
        ${Props.isOffline && 'filter: grayscale(100%);'}
      }

      :root {
        ${Theme}
      }

      @font-face {
        font-family: 'Lufga';
        font-style: normal;
        font-weight: 300;
        src: url('/fonts/Lufga-Light.otf') format('opentype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Lufga';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/Lufga-Regular.otf') format('opentype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Lufga';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/Lufga-SemiBold.otf') format('opentype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Lufga';
        font-style: normal;
        font-weight: 600;
        src: url('/fonts/Lufga-Bold.otf') format('opentype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Lufga';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/Lufga-Black.otf') format('opentype');
        font-display: swap;
      }

      html {
        ${Props.isOffline && !Props.isMobile && 'filter: grayscale(100%);'}
      }

      body {
        background-color: var(--color-background);
        color: var(--color-white);
      }

      body.fontLoaded {
        font-family: 'Lufga', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }

      * {
        text-rendering: optimizeLegibility;
        box-sizing: border-box;
        word-break: break-word;
        outline: none;
        text-decoration: none;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: Lufga, -apple-system, BlinkMacSystemFont, sans-serif !important;
        overscroll-behavior: none;
      }

      .current {
        display: none !important;
      }

      /* Selection */
      ::selection {
        color: var(--color-white);
        background: rgba(132, 67, 216, 0.4);
      }
    `}
  />
);

export default GlobalStyles;
