import * as Fonts from './constants/fonts';
import * as Spacings from './constants/spacing';
import Theme from './constants/theme';

export const PRIMARY_BORDER_WIDTH = '1px';
export const SOLID_BORDER_STYLE = 'solid';
export const DASHED_BORDER_STYLE = 'dashed';

const mobile = {
  maxWidth: '540px',
  largeMobile: '760px',
};

const breakpoints = {
  mobile: '47.95em',
  tablet: '70.95em',
};

export const mediaQuery = {
  aboveMobile: `@media only screen and (min-width: ${breakpoints.mobile})`,
  uptoMobile: `@media only screen and (max-width: ${breakpoints.mobile})`,
  onlyTablet: `@media only screen and (min-width: ${
    parseFloat(breakpoints.mobile) + 0.05
  }em) and (max-width: ${breakpoints.tablet})`,
  uptoLargeMobile: `@media only screen and (max-width: ${mobile.largeMobile})`,
  uptoNormalMobile: `@media only screen and (max-width: ${mobile.maxWidth})`,
  uptoTablet: `@media only screen and (max-width: ${mobile.maxWidth})`,
  uptoSmallDesktop: `@media only screen and (max-width : 1200px)`,
  uptoIconDesktop: '@media only screen and (max-width: 1400px)',
  uptoIconTablet: '@media only screen and (max-width: 1080px)',
  aboveTablet: `@media only screen and (min-width: ${breakpoints.tablet})`,
};

export { Fonts, Theme, Spacings, mobile, breakpoints };
