import styled from '@emotion/styled';
import { Fonts } from 'shared/styleConstants';
import styleHelper from 'utils/styleHelper';

import { TypographyProps } from './typographyProps';

const SubHeading = styled.p<TypographyProps>`
  font-weight: ${Fonts.REGULAR};
  font-size: ${Fonts.H5};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  color: ${({ color }) => (color ? `var(--color-${color})` : 'var(--color-white)')};
  margin: 1rem 0;
  -webkit-line-clamp: ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null};
`;

export default SubHeading;
