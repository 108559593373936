import { memo, useEffect, useState } from 'react';
import { MEDIA_COMPRESSOR_URL } from 'shared/constants';

const ImageLoad = ({ src, placeholder, alt = '' }: any) => {
  const [loading, setLoading] = useState(true);
  const [currentSrc, updateSrc] = useState(placeholder);

  useEffect(() => {
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      // When image is loaded replace the src and set loading to false
      setLoading(false);

      if (src.indexOf('http://') == 0 || src.indexOf('https://') == 0) {
        updateSrc(`${MEDIA_COMPRESSOR_URL}${src}`);
      } else {
        updateSrc(src);
      }
    };
  }, [src]);

  console.log(currentSrc);

  return (
    <img
      src={currentSrc}
      style={{
        opacity: loading ? 0.5 : 1,
        transition: 'opacity .15s linear',
      }}
      alt={alt}
      loading="lazy"
    />
  );
};

export default memo(ImageLoad);
