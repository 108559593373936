import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { handleLogout, handleUpdateUser } from 'redux/reducer/user';

import { getCart } from './api/order';
import { getProfile } from './api/profile';
import { getSKU } from './api/sku';
import Loader from './loader';
import { handleCartUpdate, handleSkuUpdate } from './redux/reducer/cart';
import { handleAllSku } from './redux/reducer/sku';


// import { getUser } from '@/api';
interface Props {
  component: any;
  path: any;
  res?: any;
}

const ProtectedRoute: React.FC<Props> = (props) => {
  const history = useHistory();
  const { component: Component, path, ...rest } = props;
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // eslint-disable-next-line

  useEffect(() => {
    (async () => {
      try {
        // ----------------------------------------------------------------
        //                      If user is valid
        // ----------------------------------------------------------------
        const resProfile: any = await getProfile();

        if (resProfile.data.status === 200) {
          dispatch(
            handleUpdateUser({
              phone: resProfile.data.data.phone_number,
              country: resProfile.data.data.country_code,
              name: resProfile.data.data.name,
              email: resProfile.data.data.email,
              addresses: resProfile.data.data.user_addresses,
            })
          );

          const resCart: any = await getCart();
          dispatch(
            handleCartUpdate({
              id: resCart.data.data.order.id,
              skuId: resCart.data.data.order.skuId,
              videocards: resCart.data.data.order.videocards,
              price: resCart.data.data.order.price || 0,
              videocardCount: resCart.data.data.order.videocardCount,
            })
          );

          const resSku: any = await getSKU();
          if (resSku.data.status === 200) {
            dispatch(handleAllSku(resSku.data.data));

            const skuData = await resSku.data.data.find(
              (e: any) => e.skuId === resCart.data.data.order.skuId
            );

            if (resCart.data.data.order.skuId) {
              dispatch(
                handleSkuUpdate({
                  skuId: skuData.skuId,
                  displayPrice: skuData.displayPrice,
                  maxQueue: skuData.skuQuantity,
                  skuPriceFactor: skuData.skuPriceFactor,
                })
              );
            }
          }

          localStorage.setItem('isLoggedIn', 'true');
        }
        setLoading(false);
      } catch (err) {
        // ----------------------------------------------------------------
        //                      Log user out in any other case
        // ----------------------------------------------------------------
        dispatch(handleLogout());
        toast.error('You need to login first');
        history.push('/login');
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      exact
      path={path}
      {...rest}
      render={(props) => {
        if (isLoading) return <Loader />;
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
