/* eslint-disable arrow-parens */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  userData: {
    phone: '',
    country: '',
    name: '',
    email: '',
    addresses: <any>[],
  },
  userExists: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: { value: initialState },
  reducers: {
    handleUserState: (state, action) => {
      state.value.userExists = action.payload;
    },
    handleUpdateUser: (state, action: PayloadAction<any>) => {
      state.value.userData = action.payload;
      // state.value.userData.phone = action.payload.phone_number;
      // state.value.userData.country = action.payload.country_code;
      // state.value.userData.name = action.payload.name;
      // state.value.userData.email = action.payload.email;
      // state.value.userData.addresses = action.payload.user_addresses;
      // localStorage.setItem(TOKEN_NAME, JSON.stringify(state.value));
    },
    handleAddressAdd: (state, action) => {
      state.value.userData.addresses = [
        ...state.value.userData.addresses,
        {
          id: action.payload.addressId,
          name: action.payload.receiverName,
          email: action.payload.receiverEmail,
          phoneNumber: action.payload.receiverPhoneNumber,
          countryCode: action.payload.recieverCountryCode,
          addressLine1: action.payload.addressLine1,
          addressLine2: action.payload.addressLine2,
          zipCode: action.payload.zip,
        },
      ];
      // localStorage.setItem(TOKEN_NAME, JSON.stringify(state.value));
    },
    handleAddressDelete: (state, action) => {
      state.value.userData.addresses = state.value.userData.addresses.filter(
        (data: any) => data.id != action.payload
      );
    },
    handleLogout: (state) => {
      state.value = initialState;
      localStorage.clear();
    },
  },
});

export const {
  handleUserState,
  handleUpdateUser,
  handleLogout,
  handleAddressAdd,
  handleAddressDelete,
} = userSlice.actions;

export default userSlice.reducer;
